<template>
  <en-drawer :model-value="modelValue" title="物流信息上传" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="物流公司" prop="logisticsCompany">
        <select-maintain
          v-model="form.data.logisticsCompany"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['LOGSCOM']) }"
          :props="{ value: 'name', label: 'name' }"
          allow-create
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="物流单号" prop="logisticsNo">
        <en-input v-model="form.data.logisticsNo" :disabled="form.data.status?.code === 'F'"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallComboOrderDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.init()
          this.form.data.id = this.data?.id
          this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/order/:mallComboOrderId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          update: {
            action: 'PUT /enocloud/mall/combo/order',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        rules: {
          logisticsCompany: [{ required: true, message: '请选择物流公司' }],
          logisticsNo: [{ required: true, message: '请填写物流单号' }]
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.update()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
